export default [
  {
    "id": 1,
    "name": 'success'
  },
  {
    "id": 2,
    "name": 'rejected'
  },
  {
    "id": 3,
    "name": 'in progress'
  },
];
